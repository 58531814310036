<template>
  <v-container>
    <v-card class="message">
      <v-card-title>404</v-card-title>
      <v-card-subtitle>Page not found</v-card-subtitle>
      <v-card-text>
        Sorry but we can't find the page you were looking for. You may want to
        go to <router-link to="/">home page</router-link>.
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "NotFound"
};
</script>

<style scoped>
.message {
  margin: auto;
  max-width: 600px;
}
</style>
